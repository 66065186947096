.successBg {
    background-color: #C0F1D1;
    border-radius: 6px;
}

.errorBg {
    background-color: #FCD4CC;
    border-radius: 6px;
}

.modalIcon {
    font-size: 3rem;
}

.successIcon {
    color: #2DD267;
}

.errorIcon {
    color: #F85E40;
}