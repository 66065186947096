.card-side-border {
    border-left: 5px solid #9247e3 !important;
}

.card-icon {
    font-size: 30px;
    color: #100146;
}

.card {
    height: 100%;
}