.formContent {
    z-index: 1;
}

.addForm {
    padding: 0 50px !important;
}
.form {
    width: 30% !important;
}
.formMain {
    padding: 0 3rem !important;
}

.passwordInput {
    position: relative;
}

.passwordInput svg {
    bottom: 13px;
    position: absolute;
    right: 10px;
    width: 16px;
}

.passwordField {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.logoemail{
    position:relative;
    left:17rem;
     margin-bottom:-4.8rem

}
@media screen and (max-width: 1024px) {
    .addForm {
        padding: 0 10px !important;
    }
    .form {
        width: 100% !important;
    }
 }

@media screen and (max-width: 768px) {
    .addForm {
        padding: 0 10px !important;
    }
    .form {
        width: 100% !important;
    }
    .formMain {
        padding: 0 1rem !important;
    }
    .logoemail{
        position:relative;
        left:16rem;
         margin-bottom:-4.8rem
    
    }
}

.tooltipp {
    position: relative;
    display: flex;
}
 .tooltiptext {
display: none;
width: 90px;
background-color: #9537FF;
color: #fff;
text-align: center;
border-radius: 6px;
padding: 5px 2px;
font-size: 12px;
position: inherit;
z-index: 1;
top: 100%;
right: -50%;
}

.tooltipClose {
    display: none;
    width: 80px;
    background-color: #9537FF;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 2px;
    font-size: 10px;
    /* padding: 20px; */
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    right: -70%;
    margin-top: -25px;
}

.tooltipClose::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #9537FF transparent transparent transparent;
    transform: rotate(90deg);
}

.sideLink:hover  .tooltiptext {
    display: block;
}

.submenu:hover  .tooltiptext{
    display: block;
}

.sideLink:hover  .tooltipClose {
    display: block;
}

.submenu:hover  .tooltipClose {
    display: block;
}

.scroll::-webkit-scrollbar {
    width: 0.3em;
  }
   
  .scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
  .scroll::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 10px;
  }