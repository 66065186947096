.borderedHr {
    border-top: 2px dashed #DEDFE4;
    height: 0 !important;
    background: none !important;
}
.borderedHr2 {
    border-top: 2px dashed #c5c7d1;
    height: 0 !important;
    background: none !important;
}

.formMain {
    padding: 0 3rem !important;
}

@media screen and (max-width: 768px) {
    .formMain {
        padding: 0 1rem !important;
    }
}