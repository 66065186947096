.card-section {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.card-section:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.section-title {
    color: #333;
    vertical-align: middle  !important;
}