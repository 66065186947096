.sidebar {
    width: 100%;
    min-height: calc(100vh - 70px);
    box-shadow: 2px 0px 9px rgba(16, 1, 70, 0.1);
    z-index: 5;
  }
  
  .userInfo {
    background: #100146;
    color: #fff;
    display: flex;
    gap: 5px;
  }
  
  .userInfo span {
    font-size: 12px;
  }
  
  .userInfo span,
  p {
    letter-spacing: 0.1rem;
  }
  
  .sublink {
    width: 64% !important;
    margin-left: 44px;
    margin-top: 2px;
    border-radius: 50px !important;
    font-size: 14px;
    padding-left: 8px;
    padding-bottom: 2px;
  }
  .sublinkClose {
    /* width: 75px !important; */
    padding: 0 20px;
    margin-left: 15px;
    border-radius: 20px !important;
    font-size: 14px;
    padding-left: 8px;
    padding-bottom: 2px;
  }
  .activeColor {
    color: #774ced !important;
  }
  .sideLink:hover {
    background: #f1f4fa;
    color: #774ced !important;
    text-decoration: none;
  }
  
  .sideLink {
    color: #39414a;
    text-decoration: none;
    font-weight: bold;
    padding: 5px;
    cursor: pointer;
  }
  
  /* sublink */
  
  .subSideLink {
    width: 100%;
    text-decoration: none;
    color: #39414a;
    font-weight: bold;
    padding: 7px;
    cursor: pointer;
  }
  .subSideLink:hover {
    color: #39414a;
  }
  /* .subSideLink:active {
    background: #f1f4fa;
    color: #774ced;
    text-decoration: none;
  } */
  
  .sidebarItems {
    height: calc(90vh - 75px) !important;
  }
  .scrollheight {
    overflow: auto;
    height: 100% !important;
  }
  
  .dashboardLayout {
    height: 100vh;
  }
  
  .signOut {
    color: #5932ea;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .sandwichOpen {
    width: 100%;
  }
  
  .reactTooltip {
    background: linear-gradient(180deg, #a93bff 0%, #8834ff 100%) !important;
    opacity: 1 !important;
    border-radius: 6px !important;
    font-weight: normal;
    padding: 8px !important;
    z-index: 5;
  }
  
  .sidebarLayout {
    padding-top: 70px !important;
    width: 17%;
    height: 100vh;
  }
  
  .content {
    position: absolute;
    width: 83%;
    margin-left: 17%;
    top: 70px;
    height: calc(100vh - 70px);
    z-index: 0;
  }
  .contentDiv {
    height: 100%;
    overflow-y: auto;
  }
  
  .sidebarLayoutClose {
    padding-top: 70px !important;
    width: 7%;
    height: 100vh;
  }
  
  .contentClose {
    position: absolute;
    width: 93%;
    margin-left: 7%;
    top: 70px;
    height: calc(100vh - 70px);
    z-index: 0;
  }
  
  .infoDiv {
    background: #100146;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10vh;
  }
  
  .submenu {
    text-decoration: none;
    color: #39414a;
  }
  
  .submenuItem {
    font-size: 15px;
    padding: 8px;
    border-radius: 20px;
    margin-right: 12px;
  }
  
  .submenuItem:hover {
    background: #f1f4fa;
    color: #5932ea;
  }
  
  .tooltipp {
    position: relative;
    display: flex;
  }
  .tooltiptext {
    display: none;
    width: 90px;
    background-color: #9537ff;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 2px;
    font-size: 12px;
    position: inherit;
    z-index: 1;
    top: 100%;
    right: -50%;
  }
  
  .tooltipClose {
    display: none;
    width: 80px;
    background-color: #9537ff;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 2px;
    font-size: 10px;
    /* padding: 20px; */
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    right: -70%;
    margin-top: -25px;
  }
  
  .tooltipClose::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #9537ff transparent transparent transparent;
    transform: rotate(90deg);
  }
  
  .sideLink:hover .tooltiptext {
    display: block;
  }
  
  .submenu:hover .tooltiptext {
    display: block;
  }
  
  .sideLink:hover .tooltipClose {
    display: block;
  }
  
  .submenu:hover .tooltipClose {
    display: block;
  }
  
  .scroll::-webkit-scrollbar {
    width: 0.3em;
  }
  
  .scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  
  .scroll::-webkit-scrollbar-thumb {
    background-color: rgba(178, 183, 203, 1);
    outline: 1px solid rgba(222, 223, 228, 1);
    border-radius: 6px;
  }
  .logout_sidebar_button {
    position: fixed;
    display: inline-block;
    bottom: 0;
    margin-left: 15px;
    width: 100%;
  }
  .sidebarActive {
    background-color: rgba(241, 244, 250, 1);
    color: rgba(89, 50, 234, 1);
    text-decoration: none !important;
  }
  .sidebarInactive {
    color: #545e6f;
    text-decoration: none !important;
  }
  
  @media screen and (min-width: 1800px) {
    .sidebarLayoutClose {
      width: 5%;
    }
  
    .contentClose {
      width: 95%;
      margin-left: 5%;
    }
  
    .sidebarLayout {
      width: 15%;
    }
  
    .content {
      width: 85%;
      margin-left: 15%;
    }
  }
  @media screen and (min-width: 1400px) and (max-width: 1800px) {
    .sidebarLayoutClose {
      width: 5%;
    }
  
    .contentClose {
      width: 95%;
      margin-left: 5%;
    }
  
    .sidebarLayout {
      width: 17%;
    }
  
    .content {
      width: 83%;
      margin-left: 17%;
    }
  }
  
  /* Laptop Screen */
  @media screen and (min-width: 1083px) and (max-width: 1400px) {
    .tooltipClose {
      right: -96%;
    }
  
    .contentOutlet {
      transform-origin: 0 0;
      /* width: 83%;
      height: calc(100vh - 70px); */
      scale: 0.75;
    }
  
    .sidebarLayoutClose {
      width: 7%;
    }
  
    .contentClose {
      width: 93%;
      margin-left: 7%;
    }
  
    .sidebarLayout {
      width: 20%;
    }
  
    .content {
      width: 80%;
      margin-left: 20%;
    }
  }
  
  @media screen and (min-width: 821px) and (max-width: 1082px) {
    .sidebarLayoutClose {
      width: 10%;
    }
  
    .contentClose {
      width: 90%;
      margin-left: 10%;
    }
  
    .sidebarLayout {
      width: 25%;
    }
  
    .content {
      width: 75%;
      margin-left: 25%;
    }
  }
  /* Tab and Mobile */
  @media screen and (max-width: 820px) {
    .sidebarLayout {
      width: 100%;
    }
  
    .content {
      display: none;
    }
    .sidebarLayoutClose {
      display: none;
    }
  
    .contentClose {
      width: 100%;
      margin-left: 0;
    }
  }
  
  [data-toggle="tooltip"] {
    background-color: #100146 !important ;
  }
  
  .profile_pic {
    color: transparent;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.3s ease;
  }
  .profile_pic input {
    display: none;
  }
  .profile_pic img {
    position: absolute;
    object-fit: cover;
    width: 50px;
    height: 50px;
    box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
    border-radius: 100px;
    z-index: 0;
  }
  .profile_pic .label {
    cursor: pointer;
    height: 50px;
    width: 50px;
  }
  .profile_pic:hover .label {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10000;
    color: #fafafa;
    transition: background-color 0.2s ease-in-out;
    border-radius: 100px;
    margin-bottom: 0;
  }
  .profile_pic span {
    display: inline-flex;
    padding: 0.2em;
    height: 2em;
  }
  /*  */
  .ChangePic {
    color: transparent;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.3s ease;
  }
  .ChangePic input {
    display: none;
  }
  .ChangePic img {
    position: absolute;
    object-fit: cover;
    width: 200px;
    height: 200px;
    box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
    border-radius: 100px;
    z-index: 0;
  }
  .ChangePic .label {
    cursor: pointer;
    height: 200px;
    width: 200px;
    border-radius: 100px;
  
    border:1px solid black !important;
  
  }
  .ChangePic:hover .label {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10000;
    color: #fafafa;
    transition: background-color 0.2s ease-in-out;
    border-radius: 100px;
    margin-bottom: 0;
  }
  .ChangePic span {
    display: inline-flex;
    padding: 0.2em;
    height: 2em;
  }
  .subanimate{
    animation-name: fade, slide_reverse;
    animation-duration: 200ms, 400ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear, cubic-bezier(0.23, 1, 0.32, 1);
  }
  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
    
  }
  @keyframes slide {
    0% {
      margin-top: -10px !important;
    }
    100% {
      margin-top: 0px !important;
    }
  }
  
  
  
  .fade {
    animation-name: fade, slide;
    animation-duration: 400ms, 400ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear, cubic-bezier(0.23, 1, 0.32, 1);
    animation-delay: 200ms, 0;
  }
  
  .fade_reverse {
    animation-name: fade_reverse, slide_reverse;
    animation-duration: 100ms, 200ms;
    animation-timing-function: linear, cubic-bezier(0.23, 1, 0.32, 1);
    animation-delay: 100ms, 0;
  }