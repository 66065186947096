.formCard {
    min-width: 400px !important;
    width: auto;
    padding: 3rem;
}
.disabledbutton {
    pointer-events: none;
    opacity: 0.4;
}
@media screen and (max-width: 636px) {
    .formCard {
        padding: 1rem;
        min-width: 300px !important;
    }
}

