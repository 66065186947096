/* Grid container for layout */
.grid_container {
  display: grid;
  grid-template-areas: 
    "main_banner main_banner main_banner main_banner category_A category_A"
    "main_banner main_banner main_banner main_banner category_A category_A"
    "main_sub_banner main_sub_banner main_sub_banner main_sub_banner category_B category_B"
    "discover_more discover_more discover_more discover_more category_B category_B";
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(4, 1fr);
  height: 300px;
  gap: 20px;
}

#main_banner {
  grid-area: 1 / 1 / 5 / 8;
}
#category_A {
  grid-area: 1 / 8 / 3 / 10;
}
#category_B {
  grid-area: 3 / 8 / 5 / 10;
}
#main_sub_banner {
  grid-area: 1 / 10 / 4 / 13;;
}
#discover_more {
  grid-area: 4 / 10 / 5 / 13;
}
/* Skeleton base styles */
.skeleton {
  background-color: #70809045;
  border-radius: 12px;
  animation: shimmer 1s infinite;
}

/* Skeleton variant for tall items */
.skeleton.tall {
  height: 200px; /* Adjust height as needed */
}

/* Grid item container */


/* Banner image styling */
.banner-img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

/* Button container for both image and skeleton states */
.button-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* opacity: 0; */
  visibility: visible ;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

/* Hover effect for showing the button */


/* Category grid layout */
.btn_hover{
  position: relative; /* Ensures absolute positioning is allowed for children */
}

/* .btn_hover:hover .button-container {
  opacity: 1;
  visibility: visible;
} */





/* Shimmer animation */
@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}
