.wrapper {
    height: 90vh;
}

.passwordInput {
    position: relative;
}

.passwordInput svg {
    bottom: 13px;
    position: absolute;
    right: 10px;
    width: 16px;
}

.passwordField {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}