.Trial_span{
    margin-top: -29px;
    margin-right: -37px;
    background: #F5F6FA;
    padding: 2px;
    padding-left: 6px;
    padding-right: 6px;
}
.Trial_div{
    border: 1px solid #6610f2;
    border-radius: 7px;
}
.Active_span{
    margin-top: -29px;
    margin-right: -50px;
    background: #F5F6FA;
    padding: 2px;
    padding-left: 6px;
    padding-right: 6px;
}
.Cancel_span{
    margin-top: -29px;
    margin-right: -53px;
    background: #F5F6FA;
    padding: 2px;
    padding-left: 6px;
    padding-right: 6px;
}
.cancel_div{
    border: 1px solid #6610f2;
    border-radius: 7px;
    width: 80px;
}

