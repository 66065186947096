.navtabs :global(.nav-link) {
  background-color: #ffffff !important;
  color: rgba(149, 55, 255, 1) !important;
  padding: 8px !important;
  font-size: 14px;
  border: 1px solid rgba(222, 223, 228, 1) !important;
}

.navtabs :global(.nav-link.active) {
  background-color: rgba(149, 55, 255, 1) !important;
  color: #ffffff !important;
}
.notificationModal :global(.modal-content) {
  height: calc(100vh - 92px) !important;
}

.modalBody {
  height: calc(100vh - 160px) !important;
  overflow-y: auto;
}

.DropdownMenu {
  left: -30rem !important;
  border-radius: 7px !important;
  max-height: 350px;
  overflow-y: auto;
}

.DropdownMenuSpan {
  color: #9537ff;
  text-align: center;
  font-size: 28px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.DropdownMenuFooter {
  text-align: center;
  font-size: 16px;
}
.DropdownMenuFooter :global(.dropdown-item):active {
  background-color: var(--bs-dropdown-link-hover-bg);
}
.DropdownItem {
  text-decoration: none;
}
.DropdownItem:hover {
  text-decoration: underline;
}

.DropdownItem:active {
  background-color: var(--bs-dropdown-link-hover-bg) !important;
  color: var(--bs-dropdown-link-color) !important;
}

@media screen and (max-width: 576px) {
  .DropdownMenu {
    left: -12rem !important;
    right: -6rem !important;
    max-width: 100vw;
    max-height: 85vh;
  }
}
